import { useEffect, useState } from "react";
import { IRepositoryList } from "../types/repositoryTypes";

const useRepository = (): IRepositoryList[] => {
  const [repositoryList, setRepositoryList] = useState<IRepositoryList[]>([]);
  const [repositoryList_2, setRepositoryList_2] = useState<IRepositoryList[]>(
    []
  );

  // Staging
  // const API_KEY = "AIzaSyA-6a-VCVwL6XDA2kWjOMasf7yBZxjmhwg";
  // const sheetName = "Sheet1";
  // const query = encodeURIComponent("Select *");
  // const sheetid_test = "1ECgSMYTX7JV8p7S09cxNYef_NiIrfXcuId_hSUhP5p0";
  // const base_test = `https://docs.google.com/spreadsheets/d/${sheetid_test}/gviz/tq?`;
  // const url = `${base_test}&sheet=${sheetName}&tq=${query}`;

  // Product
  const sheetId = "1K2y4MEgnM1VQhDFp-onyfrHkYvg1qIEA";
  const sheetId2 = "12Cdio-9BrM8WWROOmkQYe5YgWiL3cw5Y";

  const base = `https://docs.google.com/spreadsheets/d/${sheetId}/gviz/tq?`;
  const base2 = `https://docs.google.com/spreadsheets/d/${sheetId2}/gviz/tq?`;

  const sheetName = "Sheet1";
  const query = encodeURIComponent("Select *");

  const url = `${base}&sheet=${sheetName}&tq=${query}`;
  const url_2 = `${base2}&sheet=${sheetName}&tq=${query}`;

  // useEffect(() => {
  //   fetch(
  //     `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}?fields=sheets%2Fproperties%2Ftitle&key=${API_KEY}`
  //   )
  //     .then((res) => res.text())
  //     .then((rep) => {
  //       const response = JSON.parse(rep);

  //       console.log("response: ", response);

  //       const queryParams = response.sheets.reduce(
  //         (s: any, e: any, i: any) =>
  //           (s +=
  //             "ranges=" +
  //             e.properties.title +
  //             (i < response.sheets.length - 1 ? "&" : "")),
  //         ""
  //       );

  //       fetch(
  //         `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values:batchGet?key=${API_KEY}&` +
  //           queryParams
  //       )
  //         .then((res) => res.text())
  //         .then((req) => {
  //           const response = JSON.parse(req);
  //           const valueRanges = response.valueRanges;
  //           let cols: any = [];
  //           let rows: any = [];
  //           valueRanges?.forEach((sheet: any) => {
  //             cols = [sheet?.values[0]];

  //             sheet?.values.shift();
  //             sheet?.values.map((row: any) => {
  //               if (row) {
  //                 rows.push(row);
  //               }
  //               return row;
  //             });
  //           });

  //           let allCols: any = [];
  //           cols[0]?.map((item: any) => {
  //             allCols.push(item);
  //             return item;
  //           });

  //           let data: any = [];
  //           rows.map((row: any) => {
  //             const rowObj: any = {};
  //             row.map((rowData: any, index: number) => {
  //               allCols.map((col: any, idx: number) => {
  //                 if (idx === index) {
  //                   rowObj[col] = rowData;
  //                 }
  //                 return col;
  //               });
  //               return rowData;
  //             });
  //             data.push(rowObj);
  //             return row;
  //           });

  //           const convertKeyObject = data.map((item: any) => {
  //             item["noi_nhan"] = item["Nơi nhận"];
  //             delete item["Nơi nhận"];

  //             item["ma_chung_tu_doi_tac"] = item["Mã chứng từ đối tác"];
  //             delete item["Mã chứng từ đối tác"];

  //             item["ghi_chu_noi_dung"] = item["Ghi chú nội dung hàng hóa"];
  //             delete item["Ghi chú nội dung hàng hóa"];

  //             item["nguoi_tao"] = item["Người tạo"];
  //             delete item["Người tạo"];

  //             item["thoi_gian_tao"] = item["Thời gian tạo"];
  //             delete item["Thời gian tạo"];

  //             item["thoi_gian_giao_du_kien"] = item["Thời gian giao dự kiến"];
  //             delete item["Thời gian giao dự kiến"];

  //             return item;
  //           });
  //           setRepositoryList(convertKeyObject);
  //         });
  //     });
  // }, [sheetId, API_KEY]);

  useEffect(() => {
    fetch(url)
      .then((res) => res.text())
      .then((rep) => {
        const jsonData = JSON.parse(rep.substring(47).slice(0, -2));

        const colz: any = [];
        const idCol: any = [];

        // Column labels
        jsonData.table.cols.forEach((heading: any) => {
          if (heading.label) {
            let column = heading.label;
            colz.push(column);
          }
          if (heading.id) {
            let id = heading.id;
            idCol.push(id);
          }
        });

        // Row data
        const data: any = [];
        jsonData.table.rows.forEach((rowData: any) => {
          const row: any = {};
          idCol.forEach((colId: any, index: number) => {
            colz.forEach((ele: any, idx: number) => {
              if (colId === "C" && index === idx) {
                row[ele] = rowData.c[idx] != null ? rowData.c[idx].f : "";
              } else if (colId === "D" && index === idx) {
                row[ele] = rowData.c[idx] != null ? rowData.c[idx].v : "";
              } else if (colId === "E" && index === idx) {
                row[ele] = rowData.c[idx] != null ? rowData.c[idx].v : "";
              } else if (colId === "H" && index === idx) {
                row[ele] = rowData.c[idx] != null ? rowData.c[idx].v : "";
              } else if (colId === "X" && index === idx) {
                row[ele] = rowData.c[idx] != null ? rowData.c[idx].v : "";
              } else if (colId === "AO" && index === idx) {
                row[ele] = rowData.c[idx] != null ? rowData.c[idx].f : "";
              }
            });
          });
          data.push(row);
        });

        const convertKeyObject = data.map((item: any) => {
          item["noi_nhan"] = item["Nơi nhận"];
          delete item["Nơi nhận"];

          item["ma_chung_tu_doi_tac"] = item["Mã chứng từ đối tác"];
          delete item["Mã chứng từ đối tác"];

          item["ghi_chu_noi_dung"] = item["Ghi chú nội dung hàng hóa"];
          delete item["Ghi chú nội dung hàng hóa"];

          item["nguoi_tao"] = item["Người tạo"];
          delete item["Người tạo"];

          item["thoi_gian_tao"] = item["Thời gian tạo"];
          delete item["Thời gian tạo"];

          item["thoi_gian_giao_du_kien"] = item["Thời gian giao dự kiến"];
          delete item["Thời gian giao dự kiến"];

          return item;
        });

        setRepositoryList(convertKeyObject);
      })
      .catch((err) => console.log("Err: ", err));

    // Fetch link 2
    fetch(url_2)
      .then((res) => res.text())
      .then((rep) => {
        const jsonData = JSON.parse(rep.substring(47).slice(0, -2));

        const colz: any = [];
        const idCol: any = [];

        // Column labels
        jsonData.table.cols.forEach((heading: any) => {
          if (heading.label) {
            let column = heading.label;
            colz.push(column);
          }
          if (heading.id) {
            let id = heading.id;
            idCol.push(id);
          }
        });

        // Row data
        const data: any = [];
        jsonData.table.rows.forEach((rowData: any) => {
          const row: any = {};
          idCol.forEach((colId: any, index: number) => {
            colz.forEach((ele: any, idx: number) => {
              if (colId === "C" && index === idx) {
                row[ele] = rowData.c[idx] != null ? rowData.c[idx].f : "";
              } else if (colId === "D" && index === idx) {
                row[ele] = rowData.c[idx] != null ? rowData.c[idx].v : "";
              } else if (colId === "E" && index === idx) {
                row[ele] = rowData.c[idx] != null ? rowData.c[idx].v : "";
              } else if (colId === "H" && index === idx) {
                row[ele] = rowData.c[idx] != null ? rowData.c[idx].v : "";
              } else if (colId === "X" && index === idx) {
                row[ele] = rowData.c[idx] != null ? rowData.c[idx].v : "";
              } else if (colId === "AO" && index === idx) {
                row[ele] = rowData.c[idx] != null ? rowData.c[idx].f : "";
              }
            });
          });
          data.push(row);
        });

        const convertKeyObject = data.map((item: any) => {
          item["noi_nhan"] = item["Nơi nhận"];
          delete item["Nơi nhận"];

          item["ma_chung_tu_doi_tac"] = item["Mã chứng từ đối tác"];
          delete item["Mã chứng từ đối tác"];

          item["ghi_chu_noi_dung"] = item["Ghi chú nội dung hàng hóa"];
          delete item["Ghi chú nội dung hàng hóa"];

          item["nguoi_tao"] = item["Người tạo"];
          delete item["Người tạo"];

          item["thoi_gian_tao"] = item["Thời gian tạo"];
          delete item["Thời gian tạo"];

          item["thoi_gian_giao_du_kien"] = item["Thời gian giao dự kiến"];
          delete item["Thời gian giao dự kiến"];

          return item;
        });

        setRepositoryList_2(convertKeyObject);
      })
      .catch((err) => console.log("Err: ", err));
  }, [url, url_2]);

  let fullRepositoryList = [];
  if (repositoryList_2 && repositoryList_2.length > 0) {
    fullRepositoryList = repositoryList.concat(repositoryList_2);
  } else {
    fullRepositoryList = repositoryList;
  }
  return fullRepositoryList;
};

export default useRepository;
