import { useEffect, useState } from "react";
import { DataGrid, GridColDef, gridClasses } from "@mui/x-data-grid";
import styled from "@emotion/styled";
import {
  // Button,
  IconButton,
  Link,
  // InputAdornment,
  TextField,
} from "@mui/material";
import useRepository from "../hooks/useRepository";
import { IRepositoryList } from "../types/repositoryTypes";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
// import SearchIcon from "@mui/icons-material/SearchOutlined";
import "../assets/style.css";
import Footer from "./Footter";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ReactLoading from "react-loading";
import {
  IMG_avacycle,
  IMG_avakids,
  IMG_ankhang,
  IMG_bachhoaxanh,
  IMG_dienmaysupermini,
  IMG_thegioididong,
  IMG_topzone,
  IMG_dienmayxanh,
} from "./Img";

const columns: GridColDef[] = [
  {
    field: "noi_nhan",
    renderHeader: () => <strong>{"Nơi nhận"}</strong>,
    headerClassName: "class-header",
    flex: 1,
    sortable: false,
    renderCell: (e) => (
      <>
        <strong>{e.row.noi_nhan}</strong>
      </>
    ),
  },
  {
    field: "ma_chung_tu_doi_tac",
    headerName: "Mã chứng từ đối tác",
    flex: 1,
    sortable: false,
  },
  {
    field: "ghi_chu_noi_dung",
    headerName: "Ghi chú nội dung",
    flex: 1,
    sortable: false,
  },
  // {
  //   field: "nguoi_tao",
  //   headerName: "Người tạo",
  //   sortable: false,
  //   flex: 1,
  //   // renderCell: (e) => (
  //   //   <>
  //   //     <a target="_blank" href={e.row.url}>
  //   //       {e.row.url}
  //   //     </a>
  //   //   </>
  //   // ),
  // },
  // {
  //   field: "thoi_gian_tao",
  //   headerName: "Thời gian tạo",
  //   flex: 1,
  //   sortable: false,
  // },
  {
    field: "thoi_gian_giao_du_kien",
    headerName: "Thời gian giao dự kiến",
    flex: 1,
    sortable: false,
  },
  {
    field: "",
    headerName: "",
    flex: 1,
    sortable: false,
    renderCell: (e) => (
      <>
        {e?.row?.ma_chung_tu_doi_tac.includes("NT") ? (
          <Link
            target="_blank"
            href={`https://ntlogistics.vn/tra-van-don.html?bill=${e.row.ma_chung_tu_doi_tac}`}
            underline="hover"
          >
            <VisibilityOutlinedIcon />
            Xem chi tiết
          </Link>
        ) : (
          <span>Copy mã vận đơn và kiểm tra hành trình trên App MWG</span>
        )}
      </>
    ),
  },
];

const AppStyledProvider = styled.div`
  // height: 700px;
  width: 95%;
  margin: auto;
`;
const StyledTextField = styled(TextField)`
  margin: 10px 0;
  width: 30%;
`;

function RepositoryTable() {
  const [searchWord, setSearchWord] = useState<string>("");
  const [status, setStatus] = useState<boolean>(false);
  const repositoryList = useRepository();

  useEffect(() => {
    if (repositoryList && repositoryList.length > 0) {
      setStatus(true);
    } else {
      setStatus(false);
    }
  }, [repositoryList]);

  const globalSearch = (): IRepositoryList[] => {
    const filteredRepositories = repositoryList.filter((value) => {
      let id = value.noi_nhan.split(" - ")[0];
      return id === searchWord;
      // return id.toLowerCase().includes(searchWord?.toLowerCase());
      // ||
      // value?.ma_chung_tu_doi_tac
      //   ?.toLowerCase()
      //   .includes(searchWord?.toLowerCase()) ||
      // value?.nguoi_chuyen?.toLowerCase().includes(searchWord?.toLowerCase())
    });
    return filteredRepositories;
  };
  globalSearch();
  const filterRepositoryList: IRepositoryList[] | [] = searchWord
    ? globalSearch()
    : repositoryList;

  return (
    <div>
      <AppStyledProvider>
        <div className="header-logo-link">
          <div className="width-40">
            <h2 className="text-align">Tra cứu vận đơn POSM Marketing</h2>
            <StyledTextField
              value={searchWord}
              style={{ width: "100%" }}
              onChange={(event) => setSearchWord(event.target.value)}
              label="Nhập chính xác mã kho để tìm kiếm"
              variant="outlined"
              InputProps={{
                // startAdornment: (
                //   <InputAdornment position="start">
                //     <SearchIcon />
                //   </InputAdornment>
                // ),

                endAdornment: searchWord && (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setSearchWord("")}
                  >
                    <CancelRoundedIcon />
                  </IconButton>
                ),
              }}
            />
          </div>

          <div className="width-70">
            <h2>Nhấn vào logo để xem phân bổ POSM theo chuỗi</h2>
            <div>
              <a
                href="https://docs.google.com/spreadsheets/d/1Ygeyo3wVqJ4wcUJx-ypmzzayVIT-MGISH4gaDf3eJ8s/edit#gid=1284646124"
                target="_blank"
                rel="noopener noreferrer"
                className="logo-link-a"
              >
                <img src={IMG_thegioididong} alt="logo" />
              </a>
              <a
                href="https://docs.google.com/spreadsheets/d/1OGhrbzNnBDdffbewlHDelITzkcBosP7Gp57uaD0BHyk/edit#gid=0"
                target="_blank"
                rel="noopener noreferrer"
                className="logo-link-a"
              >
                <img src={IMG_topzone} alt="logo" />
              </a>
              <a
                href="https://docs.google.com/spreadsheets/d/11x0hFM04723SfiS3fMqI25Va2mke7bm7mPcfjL_XpYE/edit#gid=1284646124"
                target="_blank"
                rel="noopener noreferrer"
                className="logo-link-a"
              >
                <img src={IMG_dienmayxanh} alt="logo" />
              </a>
              <a
                href="https://docs.google.com/spreadsheets/d/1kf1D0UMmO6AU5-NE14OL-Ab9B8OmVQWxKlwY1pyVPik/edit#gid=1284646124"
                target="_blank"
                rel="noopener noreferrer"
                className="logo-link-a"
              >
                <img src={IMG_dienmaysupermini} alt="logo" />
              </a>
            </div>
            <div>
              <a
                href="https://docs.google.com/spreadsheets/d/1rHGYekU3dBAyQphoRzL57sf3HKNUzK2smop5AyYMiKU/edit#gid=1284646124"
                target="_blank"
                rel="noopener noreferrer"
                className="logo-link-a"
              >
                <img src={IMG_avacycle} alt="logo" />
              </a>
              <a
                href="https://docs.google.com/spreadsheets/d/1B73-nKS4oNg70MUaOM2cXSCP37YTD44iTs8XhEj6t_U/edit#gid=1284646124"
                target="_blank"
                rel="noopener noreferrer"
                className="logo-link-a"
              >
                <img src={IMG_avakids} alt="logo" />
              </a>
              <a
                href="https://docs.google.com/spreadsheets/d/1-YTqI38kLrA4rNH6t_RY-26sOsR9WaKpCTX6BHt0odk/edit#gid=1109941482"
                target="_blank"
                rel="noopener noreferrer"
                className="logo-link-a"
              >
                <img src={IMG_bachhoaxanh} alt="logo" />
              </a>
              <a
                href="https://docs.google.com/spreadsheets/d/18ndwc_bGQqu2wsDJ89leEZgg-HoMtoBQJO1QRSq12Bo/edit#gid=1284646124"
                target="_blank"
                rel="noopener noreferrer"
                className="logo-link-a"
              >
                <img src={IMG_ankhang} alt="logo" />
              </a>
            </div>
          </div>
        </div>
        {searchWord && (
          <DataGrid
            getRowHeight={() => "auto"}
            getRowId={(row) => row.ma_chung_tu_doi_tac}
            rows={filterRepositoryList && filterRepositoryList}
            columns={columns}
            pageSize={25}
            // rowsPerPageOptions={[25, 50, 100]}
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
            sx={{
              [`& .${gridClasses.cell}`]: {
                py: 1,
              },
            }}
            autoHeight
          />
        )}
        {!status && (
          <ReactLoading type="spin" color="black" className="loading" />
        )}
      </AppStyledProvider>
      <Footer />
    </div>
  );
}

export default RepositoryTable;
